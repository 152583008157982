import React from 'react'
import { Container, Row, ListGroup, ListGroupItem, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import SummerCamp from '../assets/2019SummerReg.pdf'
export default () => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Academic Summer Camp - 2019</h3>
          <p>Thank you for your interest in our Academic Summer Camp.</p>
          <p>
            Our Academic Summer Camp program runs from June 3rd, 2019 through
            June 28, 2019.
            <br />
            Monday - Friday
            <br />
            9:00 - 12:00
          </p>
          <p>
            <h4>Curriculum</h4>
            <b>Math</b>
            <br />
            <b>Reading</b>
            <br />
            <b>Writing</b>
            <br />
            <b>Fast ForWord</b> -- intensive reading remediation
            <br />
          </p>
          <p>
            <h4>Cost</h4>
            Reach Beyond Academy Students: $500 <br />
            Non Reach Beyond Academy Students: $700
          </p>
          <p>
            <a
              href={SummerCamp}
              className="text-primary"
              target="_new"
              title="Summer Camp Packet"
              alt="Summer Camp Packet"
            >
              {' '}
              Download our Summer Camp Packet.{' '}
              <FontAwesomeIcon icon={faFileDownload} />
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
