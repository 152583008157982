import React, { lazy, Suspense } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText
} from 'reactstrap'

import remark from 'remark'
import remark2react from 'remark-react'
import externalLinks from 'remark-external-links'

import packet from '../assets/18-19-AdmissionsPacket.pdf'
import poster from '../assets/0072443-educatormisconductposter_landscape_2-1.pdf'

const data = [
  {
    q: 'what is the grade range of your program?',
    a: `1st – 12th Grades`
  },
  {
    q:
      'What is the male to female student population ratio at Reach Beyond Academy?',
    a: `Approximately 60 % males and 40 % females.`
  },
  {
    q:
      'Can your school accommodate children with behavior related disabilities?',
    a: `We do not accept students whose primary disabilities are identified as an Emotional Disability or a Behavior Disability.`
  },
  {
    q:
      'Can your school accommodate children with attention / focus challenges?',
    a: `Yes, as long as the attention / focus challenges are not due to an emotional or a behavioral based disability.  
    We have a small student to teacher ratio, usually 8:1 or less in each class.  
    Our teachers give visual cues as well as auditory cues to help students with attention and focus.  
    In addition, our students work on remediating memory, attention, processing,
    sequencing, visual, auditory, and kinesthetic skills.`
  },

  {
    q: 'Does your school participate in any scholarship programs?',
    a: `Yes, we participate in the following scholarship programs:  
    § [McKay Scholarship program](http://www.fldoe.org/schools/school-choice/k-12-scholarship-programs/mckay).  
    § Gardiner Scholarship, formerly known as the Florida Personal Learning Scholarship Account program.
    `
  },
  {
    q: 'Where is your campus located?',
    a:
      '[50 South Moss Road, Winter Springs, FL 32708](https://goo.gl/maps/iV8rZvoUwdE2)'
  },
  {
    q: 'What are your School-Day hours?',
    a: `Arrival Time:   
        8:15 a.m. - 8:30 a.m. Monday - Friday    
        Dismissal Time:  
        3:30 p.m. - 3:45 p.m. Monday - Thursday  
        2:30 p.m. - 2:45 p.m. Friday, Early Release: 
    `
  },
  {
    q: 'Do you offer After Care / Extended Day?',
    a: `No, we do not offer after care / extended day.  `
  },
  {
    q: 'What Assessments do you use?',
    a: `We use the MAP Growth assessments.  
    Students may participate in applicable FSA/FCAT or SAT/ACT annual assessments at their zoned public school.
    `
  },
  {
    q: 'What is your tuition and associated costs/fees?',
    a: `**Standard Diploma Track**  
          Annual Tuition: $15,500  
          Annual Registration Fee: $250  
          Annual Materials and Activities Fee: $300  
          Additional Costs: Field Trips, Social Activities, Uniforms.  
        **Functional Skills Track**  
          Annual Tuition: $20,000  
          Annual Registration Fee: $250  
          Annual Materials and Activities Fee: $300  
          Additional Costs: Field Trips, Social Activities, Uniforms.  
      `
  },
  {
    q: 'Where can I get more information about the McKay Scholarship?',
    a: `More information about the McKay Scholarship can be found on their website [https://www.floridaschoolchoice.org/Intent/McKayIntent/StudentIntent.aspx](https://www.floridaschoolchoice.org/Intent/McKayIntent/StudentIntent.aspx)
      
Once there, you may file your intent to participate, 
check on the status of your intent, 
or look at a list of participating schools by county.

McKay funding amounts are different based on the student’s grade level 
and the county where the student last attended public school.

The amount can also fluctuate from year to year based on the education budget 
of the state and the tax base of each individual county.

For instance:
     
2018 - 2019 School Year - 252 Matrix Number


Orange County | Seminole County 
:------------- | --------------:  
Grades K-3 = $8,297 | Grades K-3 = $8,229
Grades 4-8 = $7,964 | Grades 4-8 = $7,900
Grades 9-12 = $7,636 | Grades 9-12 = $7,574 `
  },
  {
    q:
      'Where can I get more information about the Gardiner Scholarship, formerly known as Personal Learning Scholarship Account (PLSA)?',
    a: `If you need or would like information regarding the Gardiner Scholarship, formerly known as the Personal Learning Scholarship Account (PLSA), follow this link:  
    
https://www.stepupforstudents.org/plsa

Once there, click on the Apply Now link

Gardiner funding amounts are different based on the student’s grade level and the county where the student currently resides.

The amount can also fluctuate from year to year based on the education budget of the state and the tax base of each individual county.

For instance:

2018-2019 School Year

Orange County|Seminole County
---|---
Grades K-3 = $10,537|Grades K-3 = $10,457
Grades 4-8 = $10,238|Grades 4-8 = $10,162
Grades 9-12 = $9,941|Grades 9-12 = $9,869
`
  },
  {
    q: 'Where can I access your Admissions Packet?',
    a: `[Admissions Packet](${packet})`
  },
  {
    q: 'How can I report professional misconduct?',
    a: `All employees and agents of a public school district, charter school or private school have an obligation and legal responsibility to report misconduct by instructional personnel and school administrators which affects the health, safety or welfare of a student.

  Failure to report misconduct may result in penalties up to termination of employment 
  and revocation of an educator’s certificate.

  You can report any misconduct to our Director, Dr. Abualsamid, at (407)-640-5371 or  abualsamid@reachbeyondacademy.com
  
  Download our [professional misconduct reporting poster](${poster}).
  `
  }
]

const Q = d => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <b>{d.q}</b>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CardText>
              {
                remark()
                  .use(externalLinks)
                  .use(remark2react)
                  .processSync(d.a).contents
              }
            </CardText>
          </CardBody>
        </Card>
        <br />
      </Col>
    </Row>
  )
}

export default () => (
  <Container>
    <h2>Frequently Asked Questions and Answers</h2>
    <br />
    {data.map(d => Q(d))}
  </Container>
)
