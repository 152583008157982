import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import packet from '../assets/18-19-AdmissionsPacket.pdf'
import misconduct from '../assets/0072443-educatormisconductposter_landscape_2-1.pdf'
import SummerCamp from '../assets/2019SummerReg.pdf'

export default () => (
  <Container>
    <Row>
      <h3>Information and Resources</h3>
    </Row>

    <Row>
      <div>
        <Link to="/admission" className="text-primary">
          Admission Information
        </Link>
        <br />
      </div>
    </Row>
    <Row>
      <a
        href={packet}
        className="text-primary"
        target="_new"
        title="Admissions Packet"
        alt="Admissions Packet"
      >
        {' '}
        Download our Admissions Packet.{' '}
        <FontAwesomeIcon icon={faFileDownload} />
      </a>
    </Row>
    <Row>
      <Link to="/summer" className="text-primary">
        Academic Summer Camp
      </Link>
    </Row>
    <Row>
      <a
        href={SummerCamp}
        className="text-primary"
        target="_new"
        title="Summer Camp Packet"
        alt="Summer Camp Packet"
      >
        {' '}
        Download our Summer Camp Packet.{' '}
        <FontAwesomeIcon icon={faFileDownload} />
      </a>
    </Row>
    <Row>
      <Link to="/faq" className="text-primary">
        Frequently Asked Questions and Answers
      </Link>
    </Row>
    <Row>
      <Col>
        <p />
      </Col>
    </Row>
    <Row>
      <br />
      <div>
        <b>Reporting Professional Misconduct</b>
      </div>
      <div>
        All employees and agents of a public school district, charter school or
        private school have an obligation and legal responsibility to report
        misconduct by instructional personnel and school administrators which
        affects the health, safety or welfare of a student Failure to report
        misconduct may result in penalties up to termination of employment and
        revocation of an educator’s certificate.
      </div>

      <div>
        <a
          href={misconduct}
          className="text-primary"
          target="_new"
          title="Reporting Professional Misconduct Poster"
          alt="Reporting Professional Misconduct Poster"
        >
          {' '}
          Download Reporting Professional Misconduct Poster.{' '}
          <FontAwesomeIcon icon={faFileDownload} />
        </a>
      </div>
    </Row>
  </Container>
)
