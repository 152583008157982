import React, { Component } from 'react'
import TopNav from './containers/TopNav'
import { Container } from 'reactstrap'

import Routes from './routes'
import Quotes from './containers/Quotes.js'

import './App.css'

class App extends Component {
  render() {
    return (
      <div className="root">
        <div className="App">
          <TopNav />
          <br />
          <Routes />
        </div>
        <footer className="footer">
          <Container>
            <span className="text-muted">
              © 2018-2020 Reach Beyond Academy. <Quotes />
            </span>
          </Container>
        </footer>
      </div>
    )
  }
}

export default App
