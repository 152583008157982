import React from 'react'
import './Quotes.css'
const quotes = [
  'Reach beyond your limits to achieve a higher quality of life',
  'Hard work works when nothing else works',
  'It does not matter how slowly you go ... as long as you do not stop',
  'May your choices reflect your hopes, not your fears. --Nelson Mandela',
  'With great responsibility comes great power',
  'First you make your habits... then your habits make you'
]

export default () => (
  <span>{quotes[Math.floor(Math.random() * 100) % quotes.length]}</span>
)
