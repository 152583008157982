import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Alert,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/pro-regular-svg-icons'

import './Home.css'

import ts from '../images/teacher-student.jpeg'
import students_smiling from '../images/students-smiling.jpeg'
import horseback from '../images/horseback.jpg'
import students_studying from '../images/students-writing.jpeg'
import students_caps from '../images/students-caps.jpeg'
// import OpenHouse from '../assets/OpenHouse.jpg'
import biology from '../images/img_3856.jpg'
import gocarts from '../images/img_3803.jpg'
import staticelectricity from '../images/img_3839.jpg'

const C = ({ title, text, img, titleColor, list }) => (
  <Row style={{ paddingBottom: '15px' }}>
    <Col>
      <Card outline>
        <CardHeader>
          <CardTitle>
            <h2 style={{ fontWeight: 'bold', color: titleColor || 'black' }}>
              {title}
            </h2>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <CardText>
            {text && <p>{text}</p>}
            {list && (
              <ul style={{ textAlign: 'left' }}>
                {list.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
            )}
            {img && (
              <CardImg
                bottom
                width="60%"
                src={img}
                alt=""
                className="text-center"
              />
            )}
          </CardText>
        </CardBody>
        <CardFooter />
      </Card>
    </Col>
    <br />
  </Row>
)
export default () => (
  <Container className="Home">
    <Row className="lander">
      <Col>
        <h1>Reach Beyond Academy</h1>
        <h5 style={{ fontWeight: 'bold' }}>
          <a href="mailto:school@reachbeyondacademy.com">
            school@ReachBeyondAcademy.com
          </a>
        </h5>
      </Col>
    </Row>

    <C
      title="We Are"
      text="A modern, private, school for unique students with specific
              learning disabilities or mild intellectual disabilities located in
              beautiful Winter Springs, in Central Florida."
    />
    <C
      title="Why We Do What We Do"
      text="Research has shown that children with intellectual challenges perform below their cognitive profile, and that an enriched home environment can reduce the gap but schools do not. We seek to create a new kind of school that will positively affect the children's performance through enriching the environment where the child spends most of her or his time during the day, the school."
    />
    <C
      title="Our Mission"
      text="To enable children with specific learning disabilities or mild
            intellectual disabilities to find their voice, achieve agency, and build self-confidence leading to a more independent and meaningful life as adults."
    />
    <C
      title="Our Vision"
      text="A comprehensive approach to learning that combines direct instruction, 
      evidence-based practices, modern technology, peer modeling, critical thinking,
      physical activity, multi-modal enrichments,
      and community participation will produce more confident, more independent adults capable of living a meaningful life."
    />
    <Row>
      <Col>
        <Card>
          <CardTitle>
            <h1 style={{ fontWeight: 'bold', color: 'red' }}>
              Program Highlights
            </h1>
          </CardTitle>
        </Card>
      </Col>
    </Row>

    <C
      title="1st to 7th Grade Program"
      list={[
        '8:1 student to teacher ratio',
        'Intensive Remediation',
        'Direct Instruction',
        'Accommodations',
        'Modifications',
        'Social Skills Training',
        'FDLE Level 2 background screening for all teachers and staff',
        "Highly qualified teachers having one or multiple of the following: Masters degree in Special Needs Education; Bachelor's degree in subject area; More than five years of teaching experience; Special skills,knowledge or expertise in subjects taught; Autism Spectrum Disorder Graduate Certificate",
      ]}
    />
    <C
      title="8th to 12th Grade Program"
      text=""
      list={[
        '8:1 student to teacher ratio',
        'Remediation',
        'Direct Instruction',
        'Accommodations',
        'Modifications',
        'Social Skills Training',
        'Grade Level Academics',
        'Standard Diploma or Certificate of Completion',
        'FDLE Level 2 background screening for all teachers and staff',
        "Highly qualified teachers having one or multiple of the following: Masters degree in Special Needs Education; Bachelor's degree in subject area; More than five years of teaching experience; Special skills, knowledge or expertise in subjects taught.",
      ]}
      img={students_caps}
    />

    <Row />
  </Container>
)
