import React from 'react'
import {
  Nav,
  NavbarBrand,
  Navbar,
  NavbarToggler,
  NavItem,
  Collapse,
  NavLink,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'

import logo from '../images/logo-medium.png'

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Navbar
        className="navbar navbar-default"
        color="light"
        light
        expand="md"
        style={{ fontWeight: 'bold' }}
      >
        <NavbarBrand to="/" tag={Link}>
          <img
            src={logo}
            alt="Reach Beyond Academy"
            title="Reach Beyond Academy"
          />
        </NavbarBrand>
        <Nav className="ml-auto" navbar></Nav>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="mailto:school@reachbeyondacademy.com">
                <FontAwesomeIcon icon={faEnvelope} />
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="https://www.facebook.com/ReachBeyondAcademy/">
                <FontAwesomeIcon icon={faFacebook} />
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="https://twitter.com/reachbeyondHQ/">
                <FontAwesomeIcon icon={faTwitter} />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
