import React from 'react'
import { Container, Row, ListGroup, ListGroupItem, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import packet from '../assets/18-19-AdmissionsPacket.pdf'
export default () => {
  global.gtag('event', 'conversion', {
    send_to: 'AW-907627992/PKyOCKi555QBENib5bAD'
  })

  return (
    <Container>
      <Row>
        <Col>
          <h3>Admission Info</h3>
          <p>
            Thank you for contacting Reach Beyond Academy regarding your child
            prospective admission.
          </p>
          <p>
            Before considering joining our school you may want to go through our
            list of{' '}
            <Link to="/faq" className="text-primary">
              Frequently Asked Questions and Answers,
            </Link>{' '}
            which includes information about tuition and the state scholarship
            programs we participate in.
            <br />
          </p>
          <p>
            You may also want to read our{' '}
            <Link to="/story" className="text-primary">
              story
            </Link>{' '}
            to understand why our school exists and our approach to supporting
            children with special needs.
          </p>
          <p>
            Finally, please keep in mind that in Central Florida you have many
            choices for supporting your child with special needs, including Free
            and Appropriate Public Education guaranteed to you by law. If your
            child attends a public school and you feel you are not well served,
            you can, and should, work with the school's team to address your
            child's needs.
          </p>
          <p>
            Please download and reference our{' '}
            <a
              href={packet}
              className="text-primary"
              target="_new"
              title="Admissions Packet"
              alt="Admissions Packet"
            >
              {' '}
              Admissions Packet
            </a>{' '}
            which contains the list of needed documents, information you need to
            complete, and the steps to apply for admission.
          </p>
          <p>
            Please review and gather all of the documentation listed below that
            applies to your child. You will need to provide a copy of the
            documentation to Reach Beyond Academy prior to scheduling an initial
            visit/meeting with our principal. Additionally, please complete the
            requested information in the Admissions Packet and submit it with
            your documentation.
            <br />
            We will call you to schedule an initial appointment once we have
            received and reviewed the requested documentation.
          </p>
          <div>
            You may submit the information via email to
            <br />
            admissions@reachbeyondacademy.com
            <br />
            or via mail to <br />
            <address>
              Reach Beyond Academy
              <br />
              Attn: Admissions
              <br />
              P.O.Box 620855 <br />
              Oviedo, FL 32762
            </address>
          </div>

          <div>
            <h3>Required Documentation</h3>
            <ListGroup>
              <ListGroupItem>Current IEP</ListGroupItem>
              <ListGroupItem>
                Current Progress Report / Report Card
              </ListGroupItem>
              <ListGroupItem>Standardized Testing</ListGroupItem>
              <ListGroupItem>Birth Certificate</ListGroupItem>
              <ListGroupItem>
                DH 680 Form (FL Certification of Immunization)
              </ListGroupItem>
              <ListGroupItem>
                DH 3040 Form (School Entry Health Exam)
              </ListGroupItem>
            </ListGroup>
          </div>
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <h3>Additional Documentation</h3>
            <p>
              Please send copies of items below which apply to your child’s
              academic and medical history
            </p>

            <ListGroup>
              <ListGroupItem>Diagnostic Testing</ListGroupItem>
              <ListGroupItem>Psychological Reports</ListGroupItem>
              <ListGroupItem>Social History</ListGroupItem>
              <ListGroupItem>Adaptive and Behavior Scales</ListGroupItem>
              <ListGroupItem>Health Records</ListGroupItem>
              <ListGroupItem>Conference Notes</ListGroupItem>
              <ListGroupItem>Disciplinary Actions</ListGroupItem>
              <ListGroupItem>Psychiatric Reports</ListGroupItem>
              <ListGroupItem>Confidential Information</ListGroupItem>
            </ListGroup>
          </div>
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            An admissions packet that contains the complete process is available
            as a PDF download:
            <a
              href={packet}
              className="text-primary"
              target="_new"
              title="Admissions Packet"
              alt="Admissions Packet"
            >
              {' '}
              Admissions Packet
            </a>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Once we receive the review the documentation we will make an initial
            determination on whether Reach Beyond Academy is a good fit for your
            child or not. We are a small school dedicated to children with
            specific learning disabilities or intellectual disabilities. Thus,
            we are not always a good fit for all applicants. For example, we are
            not equipped to accommodate children with a primary diagnosis of an
            emotional disability or behavioral disability.
          </p>
          <p>
            If we are not a good fit for your child, we will notify you thusly.
          </p>
          <p>
            If we are potentially a good fit for your child, then the following
            steps will take place:
            <br />
          </p>
          <p>
            <strong>Facility Tour & Information Meeting:</strong>
            <br />
            Parent(s) / guardian(s) tour our campus and meet with a member of
            the administration team regarding the student’s current academic and
            learning needs. The information obtained in step 1 will also be
            discussed. Parent(s) / guardian(s) should bring any other
            documentation not listed in step 1 that will assist in determining
            the student’s primary learning issue(s).
          </p>
          <p>
            <strong>Shadow Day:</strong>
            <br />
            Student will visit our campus for 2 consecutive full days during the
            academic year or 5 consecutive full days during our academic summer
            program, to assist the admissions committee in making a decision.
          </p>
          <p>
            <strong>$250.00 Student Assessment:</strong>
            <br />
            Specific reading, math, science, and language assessments are used
            to identify the student’s present level of learning. Assessment
            information will be shared with the admissions team.
          </p>
          <p>
            <strong>Admissions Committee Review:</strong>
            <br />A thorough review of the information obtained during the steps
            above is conducted to determine if Reach Beyond Academy is a
            suitable academic program for the student’s specific learning needs.
          </p>
          <p>
            <strong>Follow up meeting with Parent:</strong> <br />A member of
            our Administrative team will share results of the evaluation and the
            decision of the admissions team regarding the child’s eligibility to
            enroll.
          </p>
          <p>
            <small>
              <strong>
                Students who are considered eligible may have to be added to a
                waiting list until enrollment space is available due to our
                limited enrollment.
              </strong>
            </small>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
