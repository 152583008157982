import React, { Suspense, lazy } from 'react'

import { Route, Switch } from 'react-router-dom'
import Home from './containers/Home'
import Info from './containers/Info'
import Admission from './containers/Admission'
import SummerCamp from './containers/SummerCamp'
import FAQ from './containers/Faq'

import NotFound from './containers/NotFound'

const Story = lazy(() => import('./containers/Story'))

const Wait = (Component) => (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component {...props} />
  </Suspense>
)
export default () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/faq" exact component={FAQ} />
    <Route path="/info" exact component={Info} />
    <Route path="/admission" exact component={Admission} />
    <Route path="/summer" exact component={SummerCamp} />
    <Route path="/story" exact component={Wait(Story)} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
)
